<!--
 * @Author: Dyf
 * @Date: 2023-11-07 11:49:20
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-11-27 09:53:57
 * @Description: 课程详情
-->
<style lang="scss" scoped>
.course-detail {
  width: 100%;
  height: 100%;
  position: relative;
  @include innerPage($p: 24px 0 0);
  @include pageTitle(0);
  .page-title {
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .wrapper-box {
    width: 100%;
    height: calc(100% - 58px);
    @include flexBox($align: flex-start);
  }
  .wrapper-left {
    width: 70%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .wrapper-chapter {
    width: calc(30% - 30px);
    height: 100%;
    margin-left: 30px;
    background: #ffffff;
    border-radius: 10px;
    .chapter-box {
      width: 100%;
      height: calc(100% - 72px);
      box-sizing: border-box;
      padding: 20px 10px 20px 30px;
    }
    .sura {
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      box-sizing: border-box;
      padding-right: 20px;
      > li {
        position: relative;
        b {
          font-size: 16px;
          color: #4c4c4c;
          line-height: 36px;
          margin-left: 36px;
        }
        .sura-item {
          font-size: 16px;
          color: #4c4c4c;
          line-height: 36px;
          margin-left: 36px;
          cursor: pointer;
          @include flexBox;
          span {
            display: inline-block;
            flex-grow: 1;
            overflow: hidden;
            font-weight: bold;
          }
          .iconfont {
            font-size: 20px;
            color: #2ac293;
            flex-shrink: 0;
            margin-left: 10px;
          }
          &:hover {
            color: #6c4ecb;
          }
        }
        .part {
          width: calc(100% - 40px);
          margin-left: 40px;
          margin-top: 10px;
          li {
            width: 100%;
            height: 42px;
            line-height: 42px;
            color: #404040;
            font-size: 16px;
            background: rgba($color: #d0cbe7, $alpha: 0.15);
            border-radius: 4px;
            box-sizing: border-box;
            padding: 0 10px;
            margin-bottom: 20px;
            cursor: pointer;
            p {
              @include flexBox;
            }
            span {
              display: inline-block;
              flex-grow: 1;
              overflow: hidden;
            }
            .iconfont {
              font-size: 20px;
              color: #2ac293;
              flex-shrink: 0;
              margin-left: 10px;
            }
            &:hover {
              background: rgba($color: #d0cbe7, $alpha: 0.24);
              box-shadow: -5px 5px 27px -10px rgba(108, 78, 203, 0.15),
                5px 5px 27px -10px rgba(108, 78, 203, 0.15);
            }
          }
        }
        &::before {
          content: "\e60f";
          font-family: "iconfont";
          display: inline-flex;
          width: 36px;
          height: 36px;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 1px;
          left: -4px;
          font-size: 22px;
          color: #d0cbe7;
        }
        &::after {
          content: "";
          width: 1px;
          height: calc(100% - 36px);
          background-image: linear-gradient(
            #d0cbe7 35%,
            rgba(255, 255, 255, 0) 0%
          );
          background-position: left;
          background-size: 1px 5px;
          background-repeat: repeat-y;
          top: 36px;
          left: 14px;
          position: absolute;
        }
      }
      &.lock {
        .part li {
          color: #888888;
          background: #f3f5f8;
          cursor: default;
          .iconfont {
            color: #888888;
            font-size: 18px;
          }
          &:hover {
            background: #f3f5f8;
            box-shadow: none;
          }
        }
      }
    }
  }
  .banner-swiper {
    width: 100%;
    height: 320px;
    border-radius: 10px;
    flex-shrink: 0;
    .el-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .banner-pagination {
      width: 100%;
      height: 64px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      @include flexBox(center);

      ::v-deep .banner-bullet {
        width: 10px;
        height: 10px;
        background-color: #fff;
        border-radius: 5px;
        margin: 0 5px;
        cursor: pointer;

        &-active {
          width: 54px;
        }
      }
    }
  }
  .detail-info {
    width: 100%;
    height: calc(100% - 350px);
    min-height: 400px;
    margin-top: 30px;
    background: #ffffff;
    border-radius: 10px;
    .detail-inner {
      width: 100%;
      height: calc(100% - 72px);
      box-sizing: border-box;
      padding: 20px 10px 20px 20px;
      .inner-content {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        box-sizing: border-box;
        padding-right: 10px;
        .title {
          color: #232323;
          text-align: center;
          font-size: 24px;
        }
        .desc {
          font-size: 16px;
          color: #696969;
          text-align: center;
          margin: 10px 0;
          line-height: 24px;
        }
        .rich-box {
          ::v-deep {
            p,
            span {
              font-size: 16px;
              line-height: 24px;
              color: #434444;
            }
            img,
            video {
              display: block;
              width: 100%;
              margin: 10px auto;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .course-detail {
    @include innerPage($p: 18px 0 0);
    .wrapper-chapter {
      width: calc(30% - 10px);
      margin-left: 10px;
      .chapter-box {
        height: calc(100% - 48px);
        padding: 10px 10px 10px 18px;
      }
      .sura {
        padding-right: 8px;
        & > li .part {
          width: calc(100% - 28px);
          margin-left: 28px;
          li {
            font-size: 14px;
          }
        }
      }
    }
    .detail-info .detail-inner {
      height: calc(100% - 48px);
      .inner-content {
        .title {
          font-size: 20px;
        }
        .desc {
          font-size: 14px;
        }
        .rich-box {
          ::v-deep {
            p,
            span {
              font-size: 14px;
              line-height: 24px;
              color: #434444;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <section class="course-detail" v-loading="dataload">
    <!-- 课程操作功能按钮 -->
    <div class="page-title">
      <!-- 已收藏禁用点击 -->
      <el-button
        type="custom_danger"
        size="small"
        v-if="detailData.is_collect == 1"
        v-loading="collectload"
        @click="handleCancelCollect"
      >
        <i class="iconfont">&#xe670;</i>取消收藏
      </el-button>
      <el-button
        type="custom_danger"
        size="small"
        v-if="detailData.is_collect == 2"
        v-loading="collectload"
        @click="handleCollect"
      >
        <i class="iconfont">&#xe66f;</i>收藏
      </el-button>
      <!-- 免费或已报名成功缴费状态显示 -->
      <el-button
        type="custom_success"
        size="small"
        v-if="detailData.is_buy == 1 || detailData.plstu_cur_pricetype == 20"
        @click="handleStudy()"
        >开始学习</el-button
      >
      <!-- 收费且未报名状态显示 -->
      <el-button
        type="custom_primary"
        size="small"
        v-if="detailData.is_buy == 3 && detailData.plstu_cur_pricetype == 10"
        @click="handleApplication"
        >报名申请</el-button
      >
      <el-button
        type="custom_primary"
        size="small"
        @click="$router.go(-1)"
        style="margin-left: auto"
      >
        返回
      </el-button>
    </div>
    <div class="wrapper-box">
      <div class="wrapper-left">
        <div class="banner-swiper swiper">
          <div class="swiper-wrapper">
            <!-- 图片组 -->
            <template v-if="Array.isArray(detailData.plstu_cur_image)">
              <div
                class="swiper-slide"
                v-for="(item, index) in detailData.plstu_cur_image"
                :key="index"
              >
                <el-image fit="scale-down" :src="formatfile(item)" />
              </div>
            </template>
            <!-- 单图 -->
            <div class="swiper-slide" v-else>
              <el-image
                fit="scale-down"
                :src="formatfile(detailData.plstu_cur_image)"
              />
            </div>
          </div>
          <div class="swiper-pagination banner-pagination"></div>
        </div>
        <div class="detail-info">
          <h4 class="box-head">
            <span class="bold">课程详情</span>
          </h4>
          <div class="detail-inner">
            <div class="inner-content">
              <h4 class="title">{{ detailData.plstu_cur_title }}</h4>
              <p class="desc">
                {{ detailData.plstu_cur_remark }}
              </p>
              <div class="rich-box richblock" v-html="detailData.plstu_cur_content"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-chapter">
        <h4 class="box-head">
          <span class="bold">课程章节</span>
        </h4>
        <div
          class="chapter-box"
          :class="{
            'no-data': $isEmpty(detailData.chapters_arr.chapters_data),
          }"
        >
          <ul
            class="sura"
            :class="{
              lock:
                detailData.is_buy != 1 && detailData.plstu_cur_pricetype == 10,
            }"
            v-show="!$isEmpty(detailData.chapters_arr.chapters_data)"
          >
            <li
              v-for="item in detailData.chapters_arr.chapters_data"
              :key="item.plstu_cur_cha_id"
            >
              <template v-if="!$isEmpty(item.children)">
                <b>{{ item.plstu_cur_cha_title }}</b>
                <ol class="part">
                  <li
                    v-for="citem in item.children"
                    :key="citem.plstu_cur_cha_id"
                    @click="handleStudy(citem)"
                  >
                    <p>
                      <span class="line-text--1st">
                        {{ citem.plstu_cur_cha_title }}</span
                      >
                      <i
                        class="iconfont"
                        v-if="
                          detailData.is_buy == 1 ||
                          detailData.plstu_cur_pricetype == 20
                        "
                        >{{
                          (file_typeof(citem.plstu_cur_cha_url) == "video" &&
                            "&#xe6c9;") ||
                          "&#xe67e;"
                        }}</i
                      >
                      <i
                        class="iconfont"
                        v-if="
                          detailData.is_buy != 1 &&
                          detailData.plstu_cur_pricetype == 10
                        "
                        >&#xe6a8;</i
                      >
                    </p>
                  </li>
                </ol>
              </template>
              <p class="sura-item" v-if="$isEmpty(item.children)">
                <span class="line-text--1st">
                  {{ item.plstu_cur_cha_title }}</span
                >
                <i
                  class="iconfont"
                  v-if="
                    detailData.is_buy == 1 ||
                    detailData.plstu_cur_pricetype == 20
                  "
                  >{{
                    (file_typeof(item.plstu_cur_cha_url) == "video" &&
                      "&#xe6c9;") ||
                    "&#xe67e;"
                  }}</i
                >
                <i
                  class="iconfont"
                  v-if="
                    detailData.is_buy != 1 &&
                    detailData.plstu_cur_pricetype == 10
                  "
                  >&#xe6a8;</i
                >
              </p>
            </li>
          </ul>
          <div
            class="no-data--empty"
            v-if="$isEmpty(detailData.chapters_arr.chapters_data) && !dataload"
          >
            <img src="@assets/images/no-data3.png" alt="" />
            <p>暂无课程章节哦~</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {
  plastucurriculumInfo,
  plastucurriculumCollect_courses,
  plastucurriculumEnroll_courses,
} from "@api/home";
import { stucurriculumcollectionDelete } from "@api/center";
import { $downloadFile } from "@api/common";
import { formatFile } from "@utils";
import Swiper, { Autoplay, Pagination, Loop } from "swiper";
import "swiper/swiper.min.css";

Swiper.use([Autoplay, Pagination, Loop]);
export default {
  computed: {
    formatfile() {
      return function (url) {
        return formatFile(url);
      };
    },
    file_typeof() {
      return function (url) {
        let file_type = url.substring(url.lastIndexOf(".") + 1); // 将字母全部转换成小写
        let type = "";
        /* 常用文件类型 */
        const wordType = ["doc", "docx"]; // word文件类型
        const excelType = ["xls", "xlsx", "xlsm"]; // excel文件类型
        const pptType = ["ppt", "pptx", "pps", "pot", "ppa"]; // 演示文稿文件类型
        const audioType = ["mp3", "wma", "aac"]; // 音频文件类型
        const videoType = [
          "mp4",
          "avi",
          "wmv",
          "rmvb",
          "rm",
          "flash",
          "mid",
          "3gp",
          "mpeg",
        ]; // 视频文件类型
        const imgType = [
          "jpg",
          "jpeg",
          "png",
          "svg",
          "gif",
          "tif",
          "bmp",
          "webp",
        ]; // 图片文件类型
        switch (file_type) {
          case "txt":
            type = "txt";
            break;
          case "pdf":
            type = "pdf";
            break;
          default:
            if (wordType.indexOf(file_type) != -1) {
              type = "word";
            }
            if (excelType.indexOf(file_type) != -1) {
              type = "excel";
            }
            if (pptType.indexOf(file_type) != -1) {
              type = "ppt";
            }
            if (audioType.indexOf(file_type) != -1) {
              type = "audio";
            }
            if (videoType.indexOf(file_type) != -1) {
              type = "video";
            }
            if (imgType.indexOf(file_type) != -1) {
              type = "img";
            }
            break;
        }
        return type;
      };
    },
  },
  data() {
    return {
      dataload: false,
      collectload: false,
      detailData: {
        chapters_arr: {
          chapters_data: [],
        },
      },
    };
  },
  created() {
    this.dataload = true;
    this.getDetail();
  },
  methods: {
    /** 获取详情 */
    async getDetail() {
      let res = await plastucurriculumInfo({
        plstu_cur_id: this.$route.params.id,
      });
      this.dataload = false;
      this.detailData = res.data;
      this.$nextTick(() => {
        if (Array.isArray(res.data)) this.initSwiper();
      });
    },
    /** 实例化swiper */
    initSwiper() {
      this.newsSwiper = new Swiper(".banner-swiper", {
        speed: 1200,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          waitForTransition: true,
        },
        pagination: {
          el: ".banner-pagination",
          clickable: true,
          bulletClass: "banner-bullet",
          bulletActiveClass: "banner-bullet-active",
        },
        preventClicks: true,
      });
    },
    /** 收藏数据 */
    async handleCollect() {
      this.collectload = true;
      await plastucurriculumCollect_courses({
        plstu_cur_id: this.detailData.plstu_cur_id,
      });
      this.getDetail();
      this.collectload = false;
    },
    /** 取消收藏 */
    async handleCancelCollect() {
      this.collectload = true;
      await stucurriculumcollectionDelete({
        plstu_cur_id: this.detailData.plstu_cur_id,
      });
      this.getDetail();
      this.collectload = false;
    },
    /** 报名申请 */
    async handleApplication() {
      let res = await plastucurriculumEnroll_courses({
        plstu_cur_id: this.detailData.plstu_cur_id,
      });
      if (res.status == 200) {
        this.$confirm("请提醒家长支付报名费用！", "申请成功", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "success",
        });
      }
    },
    /** 开始学习 */
    async handleStudy(item = null) {
    if(this.detailData.is_buy != 1 && this.detailData.plstu_cur_pricetype == 10) return;
      if (!item) {
        let chapters = this.detailData.chapters_arr.chapters_data;
        let sura_item = chapters[0];
        if (this.$isEmpty(sura_item.children)) {
          item = sura_item;
        } else {
          item = sura_item.children[0];
        }
      }
      let file_type = this.file_typeof(item.plstu_cur_cha_url);
      /* 文件非视频类型，执行下载操作 */
      if (file_type != "video") {
        let { data } = await $downloadFile(item.plstu_cur_cha_url);
        if (data.url) {
          this.$fileDownload(data.url);
        }
      }
      if (file_type == "video") {
        let name_prefix = this.$route.name.split("_");
        name_prefix.splice(name_prefix.length - 1, 1);
        sessionStorage.setItem("study_data", JSON.stringify(item));
        this.$router.push({
          name: `${name_prefix.join("_")}_STUDY`,
        });
      }
    },
  },
};
</script>